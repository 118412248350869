//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderIconButton from '@/containers/header/HeaderIconButton'
import FreeShippingBar from '@/containers/header/FreeShippingBar'

import { mapActions, mapGetters } from 'vuex'
import freeDeliveryImage from '@/assets/img/free-delivery.webp'
import { searchInfo } from '@/plugins/gtm-helper'

import { assetURL } from '~/plugins/utilities'

export default {
  components: {
    HeaderIconButton,
    FreeShippingBar,
    Logo: () => import('@/components/Logo.vue'),
    HeaderActivity: () => import('@/containers/header/HeaderActivity'),
    HeaderMenu: () => import('@/containers/header/HeaderMenu'),
    HeaderSubMenu: () => import('@/containers/header/HeaderSubMenu'),
    CurrencySelector: () => import('@/components/CurrencySelector'),
    CurrencySelectorModal: () =>
      import('@/containers/CurrencySelectorModal/index'),
    // HeaderDropdownItem: () => import('./HeaderDropdownItem'),
  },
  props: {
    triggerHideSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: {
        active: false,
        query: '',
      },
      lastQuery: '',
      searchSuggestions: [],
      hideTimer: null,
      showTimer: null,
      sidebarTransition: false,
      freeDeliveryImage,
      baseFreeShippingLimit: '60', // parseInt(process.env.BASE_FREE_SHIPPING_LIMIT),
      locales: this.$i18n.locales,
      showCurrencyModal: false,
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.isSidebarActive ? 'overflow-hidden' : '',
      },
      htmlAttrs: {
        class: this.isSidebarActive ? 'overflow-hidden' : '',
      },
    }
  },
  computed: {
    ...mapGetters('ui', [
      'isWishlistActive',
      'isCartActive',
      'isAccountActive',
      'isSidebarActive',
      'isNavbarActive',
      'shouldClearHiderTimer',
      'darkMode',
    ]),
    ...mapGetters('search', ['recentSearches']),
    ...mapGetters('cookies', ['isCookiesBarActive']),
    ...mapGetters('cart', ['total']),
    freeDeliveryTextMobile() {
      return `${this.$t('header.titles.free_delivery_over')} ${
        this.$i18n.localeProperties.symbol
      }${this.baseFreeShippingLimit}*`
    },
    shownInUK() {
      return this.$i18n.locale === 'en'
    },
    shownInNonUK() {
      return this.$i18n.locale !== 'en'
    },
    selectedCurrency() {
      return this.$store.state.settings.currency
    },
    selectedCountry() {
      return this.$store.state.settings.country
    },
    currencyList() {
      const currency = [
        ...new Map(
          this.$i18n.locales.map((item) => [item.currencyCode, item])
        ).values(),
      ]
      return currency.map((element) => {
        return {
          name: element.symbol + ' ' + element.currencyCode,
          currencyCode: element.currencyCode,
        }
      })
    },
  },
  watch: {
    shouldClearHiderTimer(val) {
      if (val && this.hideTimer) {
        this.onClearHideTimer()
      }
    },

    triggerHideSidebar() {
      this.hideSider()
    },

    isNavbarActive(val) {
      this.$root.$emit('shadow', val)
    },
  },
  mounted() {
    document.addEventListener('keydown', this.searchShortcutListener)
  },
  destroyed() {
    document.removeEventListener('keydown', this.searchShortcutListener, false)
  },
  methods: {
    ...mapActions('search', ['clear']),
    ...mapActions('ui', [
      'toggleSider',
      'toggleSidebar',
      'doShowSider',
      'doHideSider',
      'doHideNavbar',
      'doClearHiderTimer',
    ]),
    assetURL,
    handleSiderState(name) {
      if (this.showTimer || this.hideTimer) {
        return -1
      }

      this.toggleSider(name)
    },
    showSider(name, event) {
      if (event.sourceCapabilities !== undefined) {
        if (event.sourceCapabilities.firesTouchEvents) {
          // chrome and others
          return -1
        }
      } else if (this.$store.state.ui.size === 'sm') {
        // possible safari
        return -1
      }

      this.onClearHideTimer()

      this.showTimer = setTimeout(() => {
        this.doShowSider(name)

        setTimeout(() => {
          this.showTimer = null
        }, 600)
      }, 200)
    },
    titleComputed(a, b) {
      return window.innerWidth > 991
        ? this.$t(`header.mainMenu[${a}].subMenus[${b}].title`)
        : this.$t(`header.mainMenu[${a}].subMenus[${b}].mobileTitle`)
    },
    hideSider() {
      this.onClearShowTimer()

      this.hideTimer = setTimeout((e) => {
        this.doHideSider()

        this.hideTimer = null
      }, 400)
    },
    onClearHideTimer() {
      this.doClearHiderTimer(false)

      clearTimeout(this.hideTimer)

      this.hideTimer = null
    },
    onClearShowTimer() {
      clearTimeout(this.showTimer)

      this.showTimer = null
    },
    onSubmitSearch() {
      if (this.search.query !== '') {
        this.onSearch(this.search.query)
      }
    },
    onSearchQueryChange(value) {
      if (this.lastQuery === value) {
        return
      }
      if (!value.length) {
        this.searchSuggestions = []
        return
      }

      this.lastQuery = value
      this.$axios
        .$get('/search/suggestion', { params: { query: value } })
        .then((response) => {
          this.searchSuggestions = response.data
        })
        .catch((e) => {
          this.searchSuggestions = []
        })
    },
    onSearch(query) {
      // Clear search suggestions
      this.searchSuggestions = []
      if (query !== '') {
        localStorage.setItem('lastScrollPage', 1)
        localStorage.setItem('lastScrollPosition', 0)
        sessionStorage.removeItem('lastCollectionData')
        // recent searches
        this.$store.dispatch('search/add', query)

        if (this.$route.path === '/products') {
          this.$root.$emit('search', query)
        }
        this.$router.push(
          this.localePath({ name: 'products', query: { q: query } })
        )

        // GTM Search Info
        searchInfo(query)

        this.deactivateSearch()
        this.search.query = ''
      }
    },
    onClickSearchButton() {
      this.activateSearch()
      if (this.search.active && this.search.query !== '') this.onSubmitSearch()
      else {
        this.activateSearch()
        this.$refs.searchInput.focus()
        const to = setTimeout(() => {
          this.$refs.searchInput.focus()
          clearTimeout(to)
        }, 100)
      }
      //  this.activateSearch()
      //   this.search.query = ''
      //   const to = setTimeout(() => {
      //     this.$refs.searchInput.focus()
      //     clearTimeout(to)
      //   }, 100)
    },
    handleDocumentClick(e) {
      if (!this.$refs.searchForm.contains(e.target)) {
        this.deactivateSearch()
      }
    },
    activateSearch(e) {
      if (e && e.key === 'Enter') this.onSubmitSearch()
      this.search.active = true
      if (document.querySelector('.suggestions'))
        document.querySelector('.suggestions').classList.remove('hide')
      // setTimeout(() => {
      //   document.addEventListener('click', this.handleDocumentClick)
      // }, 200)
    },
    deactivateSearch() {
      this.search.active = false
      this.search.query = ''
      this.searchSuggestions = []
      document.removeEventListener('click', this.handleDocumentClick)
    },
    onEscapeInput() {
      this.deactivateSearch()
    },
    searchShortcutListener(e) {
      if (e.key === 'f' && e.metaKey) {
        e.preventDefault()
        this.$refs.searchInput.focus()
        this.activateSearch()
        return false
      }
    },
    salesMenuView() {
      /*
      viewItemList(this.$gtm, {
        products: [this.sales.dropdown],
        list: 'Menu',
      })
      */
    },
    salesItemClick() {
      /*
      viewItem(
        this.$gtm,
        {
          product: this.sales.dropdown,
          index: 1,
        },
        'Menu'
      )
      */
    },
    hideSuggestions() {
      if (document.querySelector('.suggestions')) {
        const to = setTimeout(() => {
          document.querySelector('.suggestions').classList.add('hide')
          clearTimeout(to)
        }, 100)
      }
    },
    featuredLink(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      } else if (window.innerWidth < 991) {
        this.toggleSidebar()
      }
    },
  },
}
