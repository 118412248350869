//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    quantity: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
    customTransition: {
      type: Boolean,
      default: false,
    },
    customColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('ui', ['darkMode']),
  },
}
