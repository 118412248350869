//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import freeDeliveryImage from '@/assets/img/free-delivery.webp'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      freeDeliveryImage,
      baseFreeShippingLimit: '60', // parseInt(process.env.BASE_FREE_SHIPPING_LIMIT),
    }
  },
  computed: {
    ...mapGetters('ui', ['darkMode']),
    freeDeliveryText() {
      return `${this.$t('header.titles.free_delivery_orders_over')} ${
        this.$i18n.localeProperties.symbol
      }${this.baseFreeShippingLimit}*`
    },
  },
}
