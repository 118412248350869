//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CurrencySelector',
  components: {
    CurrencySelectorModal: () =>
      import('@/containers/CurrencySelectorModal/index'),
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    customSelector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCurrencyModal: false,
    }
  },
  computed: {
    getLocaleFlag() {
      return require(`@/assets/icons/flags/${this.$i18n.localeProperties.flag}`)
    },
  },
  methods: {
    openCurrencyModal() {
      this.showCurrencyModal = !this.showCurrencyModal
      this.$emit('input', this.showCurrencyModal)
    },
    closeCurrencyModal() {
      this.showCurrencyModal = false
      this.$emit('input', false)
    },
  },
}
